import React from "react"

export default function Blog() {
  return (
    <h1>
      Coming soon!{" "}
      <span role="img" aria-label="Winking face with tongue">
        😜
      </span>
    </h1>
  )
}
